import { useEffect, useState } from "react"
import useWallet from "../../utils/wallets/useWallet";
import { createLog } from "../../utils/eventTracking";
import httpApi from "../../api";
import { useWeb3React } from "@web3-react/core";
import useWeb3ReactDeriw from "../../utils/wallets/useWeb3ReactDeriw";
import { useChainId } from "../../utils/chains";
import { useEagerConnect, useHandleUnsupportedNetwork, useInactiveListener } from "../../utils/wallets";


export default function Wallet() {

    const web3param = useWeb3ReactDeriw();
    // const web3param = useWeb3React();
    const { chainId } = useChainId();

    const [activatingConnector, setActivatingConnector] = useState();

    useEffect(() => {
        if (activatingConnector && activatingConnector === web3param.connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, web3param.connector, chainId]);

    const triedEager = useEagerConnect(setActivatingConnector);
    useInactiveListener(!triedEager || !!activatingConnector);

    useHandleUnsupportedNetwork();
    const [onupdate] = useWallet(web3param)

    useEffect(() => {
        onupdate(web3param)
        if (web3param.account) {
            createLog(web3param.account, 1)

            httpApi.connectWalletLog({address: web3param.account})
            httpApi.bountyHunterLog({address: web3param.account})
            // httpApi.getConnectWalletLog({ address: web3param.account }).then(res => {
            //     if (res && res.code == 0 && res.data) {
            //         if (res.data.is_activity_time && !res.data.is_connect) {
            //             httpApi.connectWalletLog({ address: web3param.account })
            //         }
            //     }
            // })

        }
    }, [web3param])

    return (<div></div>);
}
