import { Fragment, useState, useCallback, useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import SearchCoinDarkImg from '../../assets/images/search_coin_dark.png'
import SearchCoinLightImg from '../../assets/images/search_coin_light.png'
import CountUp from "react-countup";
import searchCoinInputImg from '../../assets/images/search_coin_input.png'
import { Listbox, Transition } from '@headlessui/react'
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatAmount, numberWithCommas } from '../../utils/numbers';
import { formatCoinAmount } from '../../utils';
import { getWhitelistedTokens } from '../../config/token';
import NoData from '../NoData';
import { get } from "lodash";
import { decimalBit } from '../../utils'
import { useChainId } from '../../utils/chains';
import { useInfoTokens } from '../../hooks/useInfoTokens';
import { useWeb3React } from "@web3-react/core";
import useWeb3ReactDeriw from '../../utils/wallets/useWeb3ReactDeriw';
import { bigNumberify } from '../../utils/numbers';
import httpApi from '../../api';
import { addListener } from '../../socket';
import { useLoadTokens } from '../../config/token';
import './index.scss'

export default function SearchToken() {
  const { tokens } = useLoadTokens()
  const {active, library} = useWeb3ReactDeriw()
//   const {active, library} = useWeb3React()
  const history = useHistory();
  const { chainId } = useChainId()
  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);
    // const { infoTokens } = useInfoTokens(null, chainId, active, bigNumberify(0), false);
    // const { infoTokens } = useInfoTokens(null, chainId, active, bigNumberify(0), false);
    const isLong = true
    const mode = useSelector(state => state.mode);
    const whitelistedTokens = getWhitelistedTokens(chainId);
    const indexTokens = whitelistedTokens.filter((token) => !token.isStable);
    const { t } = useTranslation();
    const adrFormat = (num) => {
        num = String(num)
        return decimalBit(num, formatCoinAmount(num, 2))
    }
    

    const [headData, setHeadData] = useState([])

    const getHeaderData = async () => {
        const res = await httpApi.getCoin()
        if (res && res.data && res.data.list && res.data.list.length) {
        const list = res.data.list.filter(item => item.name != 'USDT')
        setHeadData(list)
        }
    }
    const updateHeaderData = useCallback((data) => {
        if (data) {
        const list = data.filter(item => item.name != 'USDT')
        setHeadData(list)
        }
    }, [])
    const registerHeaderData = () => {
        addListener("TransactionHeaderDataToken", "MARKET_DATA", (data) => {
        updateHeaderData(get(data, "body.list", []))
        })
    }

    const onSelectToToken = async (token) => {
        // getHeadData()
        const path = infoTokens && token && infoTokens[token] && infoTokens[token].symbol ? `/trade/${infoTokens[token].symbol}-USDT` : '/trade'
        history.push({
            pathname: path,
            state: {
                address: token
            }
        })
        // if (window.location.href.indexOf('trade') > -1) {

        // } else {
        //     history.push({
        //         pathname: '/trade',
        //         state: {
        //             address: token
        //         }
        //     })
        // }
        // setToTokenAddress(swapOption, token.address);
    };

    
    const filterHeadData = (key, address) => {
    if (!headData || !headData.length || !infoTokens[address] || !infoTokens[address].symbol) {
        return 0
      }
        let data;
        for (let i = 0; i < headData.length; i++) {
          if (headData[i].name === infoTokens[address].symbol) {
            data = headData[i][key]
          }
        }
        return data || 0
    }

    const [tokenSearch, setTokenSearch] = useState("")

    useEffect(() => {
        getHeaderData()
        registerHeaderData()
    }, [])
    const optionsFilter = indexTokens.filter((i) => {
        if (tokenSearch == "") {
            return true
        }
        return i.symbol.includes(tokenSearch.toUpperCase())
    })
    
    return (
        <div className="">
            <Listbox value={''} onChange={(c) => {
                onSelectToToken(c)
            }}>
                <div className="relative">
                    <Listbox.Button className="search_coin relative">
                        <div className='select_token_img_box'>
                            <img className='select_token_img' src={mode.mode == 'dark' ? SearchCoinDarkImg : SearchCoinLightImg} />
                        </div>
                    </Listbox.Button>
                    {
                        indexTokens && indexTokens.length > 1 && <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options style={{ zIndex: 1 }} className={["SearchToken_content",].join(' ')}>

                                        <div className="SearchToken_content_search">
                                            <img src={searchCoinInputImg} />
                                            <input placeholder={t("搜索币种")} onClick={e => {
                                                e.stopPropagation()
                                            }} value={tokenSearch} onChange={(e) => setTokenSearch(e.target.value)} onFocus={e => {
                                                e.stopPropagation()
                                            }} />
                                        </div>
                                        
                                        <div className='SearchToken_content_option_head'>
                                            {/* {
                                                t('自选币种')
                                            } */}
                                        </div> 
                                <div className='SearchToken_content_item_srcoll'>
                                    {optionsFilter && optionsFilter.length ? optionsFilter.map((option, index) => (
                                        <Listbox.Option
                                            key={index}
                                            className={({ active, selected }) =>
                                                `SearchToken_content_item relative  ${(selected) ? "SearchToken_content_item_act" : ""} `
                                            }
                                            value={option.address}
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <div className='SearchToken_content_item_n'>
                                                        <img className='icon' src={option.imageUrl} />
                                                        <span
                                                        >
                                                            {option.symbol}/USDT
                                                        </span>
                                                    </div>
                                                    {

                                                        <div className='SearchToken_content_item_right'>
                                                            {infoTokens && infoTokens[option.address] ?
                                                                numberWithCommas(decimalBit( formatAmount(infoTokens[option.address][isLong ? 'maxPrice' : 'minPrice'], 30, 30), formatCoinAmount( formatAmount(infoTokens[option.address][isLong ? 'maxPrice' : 'minPrice'], 30, 30)))) : '0.00'
                                                            }
                                                            <div className={
                                                                Number(filterHeadData('adr', option.address)) > 0 ? 'g' : 'r'
                                                            }>
                                                                {
                                                                    Number(filterHeadData('adr', option.address)) > 0 ? "+" : ''
                                                                }
                                                                {
                                                                    adrFormat(filterHeadData('adr', option.address))
                                                                }%
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            )}
                                        </Listbox.Option>
                                    )) : <NoData />

                                    }
                                </div>
                            </Listbox.Options>
                        </Transition>
                    }

                </div>
            </Listbox>
        </div>
    )
}
