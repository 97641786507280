import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useSelector } from "react-redux";
import { Web3Provider } from "@ethersproject/providers";
import config from "../../config";
import Web3 from "web3";
import { getProvider } from "../rpc";
const Web3HttpProvider = require('web3-providers-http');

export default function useWeb3ReactDeriw() {
  const web3React = useWeb3React()
  const storeMnemonic = useSelector(state => state.mnemonic)
  const [newWeb3React, setNewWbe3React] = useState({})
  useEffect(() => {
    if (storeMnemonic.mnemonic) {
      const wallet = ethers.Wallet.fromMnemonic(storeMnemonic.mnemonic);
      // new ethers.providers.StaticJsonRpcProvider(
      //   provider,
      //   // @ts-ignore incorrect Network param types
      //   { chainId: web3React.chainId }
      // );
      // const newProvider = new ethers.providers.JsonRpcProvider(config.ethRpc)
      // const web3 = new Web3(newProvider)
      // web3.eth.accounts.wallet.add(wallet);
      // web3.eth.defaultAccount = wallet.address;
      
      // wallet.connect(newProvider)



    //   const web3GetSigner = new Web3(window.web3.currentProvider)
    //   // const library = new Web3Provider(web3.currentProvider)
 
    //   // web3GetSigner.setProvider(config.ethRpc)
    //   web3GetSigner.eth.accounts.wallet.add(wallet);
    //   web3GetSigner.eth.defaultAccount = wallet.address;

    //   const libraryNew = new Web3Provider(web3GetSigner.currentProvider);

    //   const options = {
    //     keepAlive: true,
    //     timeout: 20000, // milliseconds,
    //     headers: [{name: 'Access-Control-Allow-Origin', value: '*'}],
    //     withCredentials: false,
    //     agent: {http: {}, baseUrl: ''}
    // };
    
    // // const provider = new Web3HttpProvider(config.ethRpc, options);

    // // const wallet = ethers.Wallet.fromMnemonic(storeMnemonic.mnemonic);
    // // const web3 = new Web3(provider)
    // // web3.eth.accounts.wallet.add(wallet);
    // // web3.eth.defaultAccount = wallet.address;
    // // const rContract = new web3.eth.Contract(
    // //   ReferralStorageABI,
    // //   '0x197d346c774454f1c55C64133Dc2Fb3c710D1db0'
    // // );

    //   const provider = new Web3HttpProvider(config.ethRpc, options);
    //   // const provider = getProvider(undefined, web3React.chainId);

    //   const web3 = new Web3(provider)
    //   web3.setProvider(provider)
    //   web3.eth.accounts.wallet.add(wallet);
    //   web3.eth.defaultAccount = wallet.address;
    //   // const library = new Web3Provider(provider);
    //   const library = new Web3Provider(web3.currentProvider);
      // setNewWbe3React({...web3React, account: wallet.address, sourceAccount: web3React.account, sourceLibrary: web3React.library  })
      setNewWbe3React({
        ...web3React,
        account: wallet.address,
        sourceAccount: web3React.account,
        // library,
        // library: {...library,getSigner: () =>  libraryNew.getSigner(wallet.address) },
        // sourceLibrary: web3React.library
      })
    } else {
      setNewWbe3React(web3React)
    }
  }, [storeMnemonic.mnemonic, web3React.account])
  return newWeb3React 
}
