import React, { useState, useEffect } from "react";
import CModal2 from "../common/CModal2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MnemonicCopyDarkImg from '../../assets/images/mnemonic_copy_dark.png'
import MnemonicCopyLightImg from '../../assets/images/mnemonic_copy_light.png'

import EyesLightYImg from '../../assets/images/eyes_light_y.png'
import EyesLightNImg from '../../assets/images/eyes_light_n.png'
import EyesDarkYImg from '../../assets/images/eyes_dark_y.png'
import EyesDarkNImg from '../../assets/images/eyes_dark_n.png'
import copy from "copy-to-clipboard";
import {  Info, ShowToast, Success, Warning  } from "../Toast";
import "./index.scss";

const MnemonicModal = props => {
  const {onClose, visible, type, onConfirm} = props
  const [isHide, setHide] = useState(true)
  const [isNext, setNext] = useState(false)
  const { t } = useTranslation();
  const [inputList, setInputList] = useState([])
  const [randomList, setRandomList] = useState([])
  const mode = useSelector(state => state.mode);

  const storeMnemonic = useSelector(state => state.mnemonic)
  const confirm = async () => {
    for (let i = 0; i < randomList.length; i++) {
      if (storeMnemonic.mnemonicList[randomList[i] - 1] !== inputList[randomList[i]]) {
        ShowToast('confirm', t("提示"), Warning, ['助记词输入有误'])  
        return
      } 
    }
    onClose()
  }

  function generateUniqueRandomNumbers(min, max, count) {
    const array = Array.from({ length: max - min + 1 }, (_, i) => i + min);
    const randomNumbers = array
      .sort(() => 0.5 - Math.random())
      .slice(0, count);
    return randomNumbers;
  }
  
  useEffect(() => {
    const uniqueNumbers = generateUniqueRandomNumbers(1, 24, 6);
    setRandomList(uniqueNumbers)
  }, [])
  return (
    <CModal2
      onCancel={onClose}
      visible={visible}
      width={400}
      showClose={false}
      title={isNext ? '请输入对应序号的助记词' : '如果需要恢复账户，请使用此短语。将其保存到密码管理器中，或写下来存放在安全的地方。不要与任何人共享！'}
    >
      {
        isNext ?
          <div className="mnemonic_modal">
          <div className="mnemonic_content">
            {
              randomList.map((item,index) => 
                <div className="mnemonic_item_input_box" key={item + index}>
                  <span>{item}</span>
                  <input className="mnemonic_item_input" onChange={e => 
                    {
                      const list = [...inputList]
                      list[item] = e.target.value
                      setInputList(list)
                  }}/>
                </div>
              )
            }
          </div>
          <div className="confirm_tx_btn_box">
            <div className="confirm_tx_btn confirm_tx_btn2 confirm_tx_btn3 confirm_tx_btn_bg2" onClick={() => setNext(false)}>{t('返回')}</div>
            <div className="confirm_tx_btn confirm_tx_btn2 confirm_tx_btn3" onClick={confirm}>{t('确认')}</div>
          </div>
        </div> : 

        <div className="mnemonic_modal">
          <div className="mnemonic_line">
            恢复秘语
          </div>
          <div className="mnemonic_content">
            {
              storeMnemonic.mnemonicList && storeMnemonic.mnemonicList.length ? storeMnemonic.mnemonicList.map((item,index) => 
                <div className="mnemonic_item" key={item + index}>
                  {index + 1}<span>{isHide ? '***' : item}</span>
                </div>
              ) : ''
            }
          </div>
          <div className="act_box">
            <div className="copy_box" onClick={() => {
              copy(storeMnemonic.mnemonic)
              ShowToast('copy', t("提示"), Success, ['复制成功'])  
            }
            }><img src={mode.mode == 'dark' ? MnemonicCopyDarkImg : MnemonicCopyLightImg} className="copy"/></div>
            {
              isHide ? 
                <div className="eyes_box"><img className="eyes" onClick={() => setHide(false)} src={mode.mode == 'dark' ? EyesDarkNImg : EyesLightNImg}/></div> :
                <div className="eyes_box"><img className="eyes" onClick={() => setHide(true)} src={mode.mode == 'dark' ? EyesDarkYImg : EyesLightYImg}/></div>
            }
          </div>
          {
            type == 'view' ?
            <div className="confirm_tx_btn confirm_tx_btn2" onClick={onConfirm}>{t('确认')}</div> :
            <div className="confirm_tx_btn confirm_tx_btn2" onClick={() => setNext(true)}>{t('下一步')}</div>
          }
        </div>
      }
    </CModal2>
  );
};

export default MnemonicModal;
