import React from "react";
import "./index.scss";
import WalletDropdown from "../WalletDropdown/WalletDropdown";
import { useWeb3React } from "@web3-react/core";
import useWeb3ReactDeriw from "../../utils/wallets/useWeb3ReactDeriw";

const WalletConnect = (props) => {
  const { active, account } = useWeb3ReactDeriw()
  // const { active, account } = useWeb3React()

  return (
    <div className="walletconnect">
      {
        props.content && active && account ? props.content : <WalletDropdown mode={props.mode}></WalletDropdown>
      }
    </div>
  );
};

export default WalletConnect;
