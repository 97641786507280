import React, { useState, useEffect } from "react";
import CModal2 from "../common/CModal2";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { SET_MNEMONIC } from "../../store/mnemonic/action";
import i18next from "i18next";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import useWeb3ReactDeriw from "../../utils/wallets/useWeb3ReactDeriw";
import GenerateLoadingImg from '../../assets/images/generate_loading.png'
import RemembermeDarkImg from '../../assets/images/rememberme_dark.png'
import RemembermeLightImg from '../../assets/images/rememberme_light.png'
import GenerateSignYImg from '../../assets/images/generate_sign_y.png'

import EyesLightYImg from '../../assets/images/eyes_light_y.png'
import EyesLightNImg from '../../assets/images/eyes_light_n.png'
import InputClearLigntImg from '../../assets/images/input_clear_light.png'
import EyesDarkYImg from '../../assets/images/eyes_dark_y.png'
import EyesDarkNImg from '../../assets/images/eyes_dark_n.png'
import InputClearDarkImg from '../../assets/images/input_clear_dark.png'
import {  Info, ShowToast, Success, Warning  } from "../Toast";

import { ethers, providers } from "ethers";
import config from "../../config";
import ReferralStorageABI from '../../contracts/abi/ReferralStorageABI.json'
import {deriveHDKeyFromEthereumSignature , exportMnemonicAndPrivateKey} from '../../utils/privateKey'
import MetamaskIcon from '../../assets/images/metamask.png'
import {Switch, Tooltip} from 'antd'
import {walletClient} from '../../utils/wallets/config'
import Web3 from 'web3'
import "./index.scss";

const crypto = require('crypto');
const forge = require('node-forge');

const SecondaryModal = props => {
  const {onClose, visible, setMnemonicModal} = props
  const {account, library} = useWeb3ReactDeriw()
  // const {account, library} = useWeb3React()
  const { t } = useTranslation();
  const mode = useSelector(state => state.mode);
  
  const storeMnemonic = useSelector(state => state.mnemonic)
  const dispatch = useDispatch();
  const [password, setPassword] = useState('')
  const [isShowPassword, setShowPassword] = useState(false)
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [isShowPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const [mnemonicData, setMnemonicData] = useState({})

  const [isEnterPassword, setEnterPassword] = useState(false)

  const [sign1, setSign1] = useState('')
  const [sign1Loading, setSign1Loading] = useState(false)
  const [sign2Loading, setSign2Loading] = useState(false)
 
  const getLang = () => {
    return i18next.language === 'ZH'
  }


// AES-256 加密
const encrypt = (text, key) => {
  // const iv = new Uint8Array([219, 231, 253, 162, 44, 23, 19, 197, 197, 95, 249, 45, 169, 52, 72, 242]) // 生成随机初始化向量
  const iv = crypto.randomBytes(16); // 生成随机初始化向量
  const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key, 'hex'), iv);
  let encrypted = cipher.update(text, 'utf8', 'hex');
  encrypted += cipher.final('hex');
  // return  encrypted; // 返回 IV 和加密数据
  return {
    encrypt: iv.toString('hex') + ':' + encrypted,
    encrypt32by: iv.toString('hex') + encrypted,
  }; // 返回 IV 和加密数据
};

  const goToSign = async () => {
    if (sign1Loading) {
      return
    }
    setSign1Loading(true)
    const signature_1 = await walletClient.signMessage({ 
      account,
      message: 'Deriw Chain',
    }).then(res => {
      console.log('%c res', 'color: #00A0E9; font-size: 26px; font-weight: blod;', res);
      if (res) {
        setEnterPassword(true)
        setSign1(res)
      }
      setSign1Loading(false)
    }).catch(e => {
      setSign1Loading(false)
    })
    // deriveHDKeyFromEthereumSignature , exportMnemonicAndPrivateKey
  }

  const createHmac = async() => {
    if (!password || !passwordConfirm || !sign1 || sign2Loading) {
      return
    }

    if (password !== passwordConfirm) {

      ShowToast('passwordConfirm', t("提示"), Warning, ['两次密码输入不一致'])
      // ShowToast('passwordConfirm', t("提示"), Warning, [t("")])
      return
    }

    setSign2Loading(true)
    var hmac = forge.hmac.create();
    hmac.start('sha256', password);
    hmac.update(sign1);
    const hex = hmac.digest().toHex()
    const mon = deriveHDKeyFromEthereumSignature(hex)
    setMnemonicData(mon)
    setEnterPassword(false)


    const signature_2 = await walletClient.signMessage({ 
      account,
      message: 'Deriw Chain',
    }).then(res => {
      if (res) {
        if (sign1 == res) {
          dispatch({ type: SET_MNEMONIC.type, payload: {...mon, mnemonicList: mon.mnemonic.split(' ')} });
          console.log('%c mon', 'color: #00A0E9; font-size: 26px; font-weight: blod;', mon);
          if (window.web3) {
            // const wallet = ethers.Wallet.fromMnemonic(mon.mnemonic);
            // const web3 = new Web3(window.web3.currentProvider)
            // web3.eth.accounts.wallet.add(wallet);
            // web3.eth.defaultAccount = wallet.address;
            // console.log('%c web3', 'color: #00A0E9; font-size: 26px; font-weight: blod;', web3);
            // console.log('%c window', 'color: #00A0E9; font-size: 26px; font-weight: blod;', window.web3);
            // const signature = sign1; // 生成签名 x
            
            // // 生成 AES-256 密钥
            // const key = crypto.createHash('sha256').update(signature).digest('hex'); // 使用签名生成密钥
            
            // // 计算助记词 w
            // const mnemonic = encrypt('12', key); // 使用 AES-256 加密密码
            // console.log('%c mnemonic', 'color: #00A0E9; font-size: 26px; font-weight: blod;', mnemonic);
            // const mne = web3.eth.accounts.privateKeyToAccount(mnemonic.encrypt32by);
            // console.log('%c mne', 'color: #00A0E9; font-size: 26px; font-weight: blod;', mne);
            // console.log('%c wallet', 'color: #00A0E9; font-size: 26px; font-weight: blod;', wallet);
            // console.log('%c vdf', 'color: #00A0E9; font-size: 26px; font-weight: blod;', );





      // const web3 = new Web3(window.web3.currentProvider)
        // web3.eth.accounts.wallet.add(mne);
        // web3.eth.defaultAccount = mne.address;

              // const wallet = ethers.W`allet.fromMnemonic(storeMnemonic.mnemonic);
              // console.log('%c wallet', 'color: #00A0E9; font-size: 26px; font-weight: blod;', wallet);
              // console.log('%c storeMnemonic', 'color: #00A0E9; font-size: 26px; font-weight: blod;', mon);
            //   const newProvider = new ethers.providers.JsonRpcProvider(config.ethRpc)
            //  const newWallet = wallet.connect(newProvider)
            //  console.log('%c newWallet', 'color: #00A0E9; font-size: 26px; font-weight: blod;', newWallet);
            //   console.log('%c newProvider', 'color: #00A0E9; font-size: 26px; font-weight: blod;', newProvider);
              // provider
              // const web3 = new Web3(window.web3.currentProvider)
        
             
          }
          setSign2Loading(false)
          onClose()
          setMnemonicModal(true)
        } else {
          throw new Error(
            'Your wallet does not support deterministic signing. Please switch to a different wallet provider.'
          );
        }
      }
    }).catch(e => {
      setSign2Loading(false)
    })
    // console.log('%c storeMnemonic', 'color: #00A0E9; font-size: 26px; font-weight: blod;', storeMnemonic);
    // console.log('%c hmac', 'color: #00A0E9; font-size: 26px; font-weight: blod;', hex);
    // console.log('%c deriveHDKeyFromEthereumSignature', 'color: #00A0E9; font-size: 26px; font-weight: blod;', );
  }

  return (
    <CModal2
      onCancel={onClose}
      visible={visible}
      width={400}
      title={isEnterPassword ? '设置密码': <><img src={MetamaskIcon} className="secondary_metamask_icon"/>签署消息</>}
    >
      {
        !isEnterPassword ?
        <div className="cancel_confirm_tx_secondary">
        <div className="confirm_tx_line">
          签名用于验证您的所有权并确认钱包兼容性。新用户将收到两个签名请求。
        </div>
        {
          sign1 && mnemonicData.mnemonic ?
          <>
          
        <div className="generate generate3">
          <img src={GenerateSignYImg} className="generate_loading_img"/>
          <div className="generate_right">
            <div className="generate_right_top">生成您的Deriw链钱包</div>
            <div className="generate_right_bot">验证您是否拥有此钱包</div>
          </div>
        </div>
        <div className="generate generate2">
          <img src={GenerateLoadingImg} className="generate_loading_img"/>
          <div className="generate_right">
            <div className="generate_right_top">验证钱包兼容性</div>
            <div className="generate_right_bot">确保您的钱包受到支持。</div>
          </div>
        </div>
          </> : 
          <div className="generate">
            <img src={GenerateLoadingImg} className="generate_loading_img"/>
            <div className="generate_right">
              <div className="generate_right_top">生成您的Deriw链钱包</div>
              <div className="generate_right_bot">验证您是否拥有此钱包</div>
            </div>
          </div>
        }
        <div className="confirm_tx_line2">
          <div className="rememberme">记住我 
            <Tooltip title={t('仅在您使用属于自己的安全设备时才使用“记住我”。如果您使用的是公共设备或非安全设备，选择此项可能导致您的密钥和信息泄露给他人。')}>
              <img src={mode.mode == 'dark' ? RemembermeDarkImg : RemembermeLightImg}/>
            </Tooltip>
          </div>

          <Switch
            className="btn_switch"
            // disabled={disabled} loading={loading} checked={data.isResubmit} onChange={onChange}
          />
        </div>

        <div className="confirm_tx_line3">
          签名<span className="yes">可用</span>，而且不会发送交易。
        </div>
        <div className="confirm_tx_btn" onClick={
          () => {
            if (mnemonicData.mnemonic) {
              createHmac()
            }else {
              goToSign()
            }
          } 
          }>{t('确认')}</div>
        <div className="confirm_tx_line4">
          检查您的钱包，查看是否有新请求
        </div>
      </div> : 
      <div className="cancel_confirm_tx_secondary">
        <div className="confirm_tx_line5">
          初始密码
        </div>
        <div className="cancel_confirm_tx_secondary_inputbox">
          <input type={isShowPassword ? 'text' : 'password'} value={password} onChange={e => setPassword(e.target.value)} placeholder="输入初始密码" className="input" />
          {
            password && <img className="clear" src={mode.mode == 'dark' ? InputClearDarkImg : InputClearLigntImg} onClick={() => setPassword('')}/>
          }
          {
            isShowPassword ? 
            <img onClick={() => setShowPassword(false)} className="eyes" src={mode.mode == 'dark' ? EyesDarkYImg : EyesLightYImg} /> : 
            <img onClick={() => setShowPassword(true)} className="eyes" src={mode.mode == 'dark' ? EyesDarkNImg : EyesLightNImg} />
          }
        </div>
        <div className="confirm_tx_line5">
          请再次输入
        </div>
        <div className="cancel_confirm_tx_secondary_inputbox">
          <input type={isShowPasswordConfirm ? 'text' : 'password'} value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)} placeholder="请再次输入密码" className="input" />
          {passwordConfirm && <img className="clear" src={mode.mode == 'dark' ? InputClearDarkImg : InputClearLigntImg} onClick={() => setPasswordConfirm('')}/>}
          {
            isShowPasswordConfirm ? 
            <img onClick={() => setShowPasswordConfirm(false)} className="eyes" src={mode.mode == 'dark' ? EyesDarkYImg : EyesLightYImg} /> : 
            <img onClick={() => setShowPasswordConfirm(true)} className="eyes" src={mode.mode == 'dark' ? EyesDarkNImg : EyesLightNImg} />
          }
        </div>
        <div className="confirm_tx_btn confirm_tx_btn2" onClick={createHmac}>{t('确认')}</div>
      </div>
      }
      
    </CModal2>
  );
};

export default SecondaryModal;
