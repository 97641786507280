import { combineReducers } from "redux";
import mode from "./mode/reducer";
import mnemonic from './mnemonic/reducer'

const appReducer = combineReducers({
  mode,
  mnemonic
});

export default appReducer;
