import { SET_MNEMONIC } from "./action";

// const t = localStorage.getItem('app_setting_theme')
const t = window.document.documentElement.getAttribute('data-theme')
const navState = { mode: t ? t : 'dark' };

const mode = (state = {}, action) => {
  if (action.type === SET_MNEMONIC.type) {
    return action.payload;
  } else {
    return state;
  }
};

export default mode;
