import { keccak256 } from 'ethereum-cryptography/keccak';
// import { stripHexPrefix } from './helpers';
import { HDKey } from '@scure/bip32';
import { entropyToMnemonic, mnemonicToSeedSync } from '@scure/bip39';
import { wordlist } from '@scure/bip39/wordlists/english';
import { ethers } from 'ethers';
import Web3 from 'web3'


// mnemonic: string;
// privateKey: Uint8Array | null;
// publicKey: Uint8Array | null;
export const deriveHDKeyFromEthereumSignature = (signature) => {
  // 将以太坊签名去掉前缀并转换为缓冲区
  const buffer = Buffer.from(signature, 'hex');
//   const buffer = Buffer.from(stripHexPrefix(signature), 'hex');
//   // 检查签名长度是否为65字节
// if (buffer.length !== 65) {
//     throw new Error('Signature must be 65 bytes');
// }
  
  // 去掉签名中的 'v' 值，只取前64字节
  const rsValues = buffer.subarray(0, 64);
  // 使用 Keccak-256 对 'r' 和 's' 值进行哈希得到熵值
  const entropy = keccak256(rsValues);
  // 调用 exportMnemonicAndPrivateKey 函数生成助记词和密钥对
  return exportMnemonicAndPrivateKey(entropy);
};


// entropy: Uint8Array,
// path: string = "m/44'/118'/0'/0/0",
export const exportMnemonicAndPrivateKey = (
  entropy,
  path =  "m/44'/118'/0'/0/0",
) => {
  // 根据熵值生成助记词
  const mnemonic = entropyToMnemonic(entropy, wordlist);
  
  // 根据助记词生成种子
  const seed = mnemonicToSeedSync(mnemonic);
  
  // 根据种子生成主HD密钥
  const hdkey = HDKey.fromMasterSeed(seed);
  
  // 根据指定路径派生子HD密钥
  const derivedHdkey = hdkey.derive(path);
  
  // 如果HD密钥不存在私钥，抛出错误
  if (!hdkey.privateKey) {
    throw new Error('null hd key');
  }
//   const web3 = new Web3(window.web3.currentProvider)
  
//   console.log('%c account', 'color: #00A0E9; font-size: 26px; font-weight: blod;', web3.eth.accounts.privateKeyToAccount(derivedHdkey.publicKey));
//   window.web3.eth.accounts.privateKeyToAccount(mnemonic.encrypt32by);
  // 返回助记词、私钥和公钥
  return {
    mnemonic,
    privateKey: derivedHdkey.privateKey,
    publicKey: derivedHdkey.publicKey,
  };
};