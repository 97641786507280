import React, { useState, useEffect, useRef, useMemo } from "react";
import { NETWORK_METADATA } from '../../../config/chains'
import "../index.scss";
import {
  L2ToL1Message, ChildTransactionReceipt,
} from '@arbitrum/sdk'
import { utils, providers, Wallet } from 'ethers'
import { useTranslation } from "react-i18next";
import { ShowToast, Info, Success, Warning } from "../../Toast";
import { formatAmount } from "../../../utils/numbers";
import { CURRENT_PROVIDER_LOCALSTORAGE_KEY } from "../../../config/localStorage";
import { getEip6963Provider } from "../../../utils/wallets";
import dayjs from "dayjs";
import { CountTime } from '../../Time'
import config from '../../../config'
import { useWeb3React } from "@web3-react/core";
import useWeb3ReactDeriw from "../../../utils/wallets/useWeb3ReactDeriw";
import { switchNetwork } from "../../../utils/wallets";
import useWallet from "../../../utils/wallets/useWallet";

const bignumber_1 = require("@ethersproject/bignumber");
const Outbox__factory_1 = require("@arbitrum/sdk/dist/lib/abi/factories/Outbox__factory");
const networks_1 = require("@arbitrum/sdk/dist/lib/dataEntities/networks");
const RollupUserLogic__factory_1 = require("@arbitrum/sdk/dist/lib/abi/factories/RollupUserLogic__factory");
const errors_1 = require("@arbitrum/sdk/dist/lib/dataEntities/errors");
const NodeInterface__factory_1 = require("@arbitrum/sdk/dist/lib/abi/factories/NodeInterface__factory");
const constants_1 = require("@arbitrum/sdk/dist/lib/dataEntities/constants");
const eventFetcher_1 = require("@arbitrum/sdk/dist/lib/utils/eventFetcher");
const arbProvider_1 = require("@arbitrum/sdk/dist/lib/utils/arbProvider");

// e.stopPropagation()
const TransactionTd = ({ transaction, tab, getHistory, refNetworks, refNetworkLayers, pendingWithdrawal, setPendingWithdrawal }) => {

  const web3param = useWeb3ReactDeriw();
  // const web3param = useWeb3React();
  const { library, account, chainId, active } = web3param;
  const { t } = useTranslation();
  const [claimLoading, setClaimLoading] = useState(false)
  const [onupdate] = useWallet(web3param, true)
  const refRpc = useRef({})


  useEffect(() => {
    onupdate(web3param)
  }, [web3param])

  useEffect(() => {
    return () => {
      setClaimLoading(false)
    }
  }, [])



  const loadRpcProvider = (chainId, rpc) => {
    if (!refRpc.current[chainId]) {
      refRpc.current[chainId] = new providers.JsonRpcProvider(rpc)
    }
    return refRpc.current[chainId]
  }

  const claim = async (tx) => {
    console.log('%c tx', 'color: #00A0E9; font-size: 26px; font-weight: blod;', tx);
    if (claimLoading) {
      return
    }
    if (tx.status != "L1CanClaim") {
      return
    }
    if (tx.to_chain_id != chainId) {
      // switchChain("0x" + tx.to_chain_id.toString(16))
      switchNetwork(tx.to_chain_id, active)
      setClaimLoading(false)
      return
    }

    const ToastId = 'claim'
    setClaimLoading(true)
    ShowToast(ToastId, t("提示"), Info, [t('Pending')])
    let toProvider = (refNetworks.current[chainId])
    toProvider = loadRpcProvider(refNetworks.current[chainId], toProvider.rpc)

    // let fromProvider = (refNetworks.current[pendingWithdrawal[tx.l2_tx_hash_2].chainId])

    // fromProvider = loadRpcProvider(pendingWithdrawal[tx.l2_tx_hash_2].chainId, fromProvider.rpc) 


    const toRPC = refNetworkLayers.current["2"]
    const l2ChainID = toRPC.chain_id
    const l2Provider = loadRpcProvider(l2ChainID, toRPC.rpc)
    const l2tx = await l2Provider.getTransactionReceipt(tx.l2_tx_hash_2)
    const l2TxReceipt = new ChildTransactionReceipt(l2tx)
    const [event] = l2TxReceipt.getChildToParentEvents()
    // if (pendingWithdrawal[tx.l2_tx_hash_2].assetType == "ERC20") {
    try {
      // const messageWriter = L2ToL1Message.fromEvent(library.getSigner(), event, toProvider)
      // const res123 = await messageWriter.execute(l2Provider)
      // const res2 = await res123.wait()
      // const newPendingWithdrawal = pendingWithdrawal
      // delete newPendingWithdrawal[tx.l2_tx_hash_2]
      // setPendingWithdrawal(newPendingWithdrawal)
      // return
      const l2Network = await (0, networks_1.getArbitrumNetwork)(l2Provider);
      let sendRootSize = 0
      const rollup = RollupUserLogic__factory_1.RollupUserLogic__factory.connect(l2Network.ethBridge.rollup, toProvider);
      const latestConfirmedNodeNum = await rollup.callStatic.latestConfirmed();
      // const l2BlockConfirmed = await this.getBlockFromNodeNum(rollup, latestConfirmedNodeNum, l2Provider);
      const { createdAtBlock } = await rollup.getNode(latestConfirmedNodeNum);
      let createdFromBlock = createdAtBlock;
      let createdToBlock = createdAtBlock;
      const eventFetcher = new eventFetcher_1.EventFetcher(rollup.provider);
      const logs = await eventFetcher.getEvents(RollupUserLogic__factory_1.RollupUserLogic__factory, t => t.filters.NodeCreated(latestConfirmedNodeNum), {
        fromBlock: createdFromBlock.toNumber(),
        toBlock: createdToBlock.toNumber(),
        address: rollup.address,
      });
      if (logs.length > 1)
        throw new errors_1.ArbSdkError(`Unexpected number of NodeCreated events. Expected 0 or 1, got ${logs.length}.`);


      const arbitrumProvider = new arbProvider_1.ArbitrumProvider(l2Provider);
      if (!logs[0]) {
        console.warn('No NodeCreated events found, defaulting to block 0');
        return arbitrumProvider.getBlock(0);
      }
      const parsedLog = {
        afterState: {
          blockHash: logs[0].event.assertion.afterState.globalState.bytes32Vals[0],
          sendRoot: logs[0].event.assertion.afterState.globalState.bytes32Vals[1],
        },
      }

      const l2Block = await arbitrumProvider.getBlock(parsedLog.afterState.blockHash);
      if (!l2Block) {
        throw new errors_1.ArbSdkError(`Block not found. ${parsedLog.afterState.blockHash}`);
      }
      if (l2Block.sendRoot !== parsedLog.afterState.sendRoot) {
        throw new errors_1.ArbSdkError(`L2 block send root doesn't match parsed log. ${l2Block.sendRoot} ${parsedLog.afterState.sendRoot}`);
      }
      const l2BlockConfirmed = l2Block;
      // const l2BlockConfirmed = await this.getBlockFromNodeLog(l2Provider, logs[0]);

      const sendRootSizeConfirmed = bignumber_1.BigNumber.from(l2BlockConfirmed.sendCount);
      if (sendRootSizeConfirmed.gt(event.position)) {
        sendRootSize = sendRootSizeConfirmed;
      }
      else {
        // if the node has yet to be confirmed we'll still try to find proof info from unconfirmed nodes
        // const latestNodeNum = await rollup.callStatic.latestNodeCreated();
        // if (latestNodeNum.gt(latestConfirmedNodeNum)) {
        //   // In rare case latestNodeNum can be equal to latestConfirmedNodeNum
        //   // eg immediately after an upgrade, or at genesis, or on a chain where confirmation time = 0 like AnyTrust may have
        //   const l2Block = await this.getBlockFromNodeNum(rollup, latestNodeNum, l2Provider);
        //   const sendRootSize = bignumber_1.BigNumber.from(l2Block.sendCount);
        //   if (sendRootSize.gt(this.event.position)) {
        //     sendRootSize = sendRootSize;
        //   }
        // }
      }


      if (!sendRootSize) {
        throw new errors_1.ArbSdkError('Node not yet created, cannot get proof.');
      }
      const nodeInterface = NodeInterface__factory_1.NodeInterface__factory.connect(constants_1.NODE_INTERFACE_ADDRESS, l2Provider);
      const outboxProofParams = await nodeInterface.callStatic.constructOutboxProof(sendRootSize.toNumber(), event.position.toNumber());
      const proof = outboxProofParams.proof;

      const outbox = Outbox__factory_1.Outbox__factory.connect(l2Network.ethBridge.outbox, library.getSigner());
      // return
      const res = await outbox.executeTransaction(proof, event.position, event.caller, event.destination, event.arbBlockNum, event.ethBlockNum, event.timestamp, event.callvalue, event.data, {});
      // return
      getHistory(2)
      ShowToast(ToastId, t("提示"), Success, [t('submitted')])
      setClaimLoading(false)
    } catch (e) {
      ShowToast(ToastId, t("提示"), Warning, [t('failed')])
      console.log("claimn e",e)
      setClaimLoading(false)
    }
    // } else {
    //   try {
    //     const messageWriter = L2ToL1Message.fromEvent(library.getSigner(), pendingWithdrawal[tx.l2_tx_hash_2].raw, toProvider)
    //     const res = await messageWriter.execute(fromProvider)
    //     const newPendingWithdrawal = JSON.stringify(JSON.parse(pendingWithdrawal))
    //     delete newPendingWithdrawal[tx.l2_tx_hash_2]
    //     setPendingWithdrawal(newPendingWithdrawal)
    //     getHistory(2)
    //     setClaimLoading(false)
    //   } catch (e) {
    //     setClaimLoading(false)
    //   }
    // }
  }


  return (
    <div className="SettingsModal_table_head">
      <div className="SettingsModal_table_head_item SettingsModal_table_td_item ">
        {
          transaction.status == "L1CanClaim" ?
            t('可领取') :
            tab == 1 && transaction.status == 'L3Confirmed' ? t('Cross_已完成') :
              tab == 2 && transaction.status == 'L1Executed' ? t('Cross_已完成') :
                t('处理中')}</div>
      <div className="SettingsModal_table_head_item SettingsModal_table_td_item SettingsModal_table_td_item1">
        {dayjs(transaction.created_at * 1000).format('D/M/YYYY')}
        <br />
        {dayjs(transaction.created_at * 1000).format('h:m:s A')}
      </div>
      <div className="SettingsModal_table_head_item SettingsModal_table_td_item SettingsModal_table_td_item2">{formatAmount(transaction.from_token_amount, transaction.decimals, 2)}{" "}{transaction.symbol}</div>
      <div className="SettingsModal_table_head_item SettingsModal_table_td_item SettingsModal_table_td_item3">
        <img src={`${config.tokenAssetBaseUrl}${NETWORK_METADATA[transaction.from_chain_id].nativeCurrency.symbol?.toUpperCase()}.png`} className="SettingsModal_table_td_item_img" />
        <div className="SettingsModal_table_td_item_text">
          {/* Arbitrum Sepolia */}
          {
            NETWORK_METADATA[transaction.from_chain_id].chainName
          }
        </div>
      </div>
      <div className="SettingsModal_table_head_item SettingsModal_table_td_item SettingsModal_table_td_item3">
        <img src={`${config.tokenAssetBaseUrl}${NETWORK_METADATA[transaction.to_chain_id].nativeCurrency.symbol?.toUpperCase()}.png`} className="SettingsModal_table_td_item_img" />
        <div className="SettingsModal_table_td_item_text">
          {
            NETWORK_METADATA[transaction.to_chain_id].chainName
          }
        </div>
      </div>
      <div className="SettingsModal_table_head_item SettingsModal_table_td_item SettingsModal_table_td_item4">
        {
          tab == 2 && transaction.status == "L1Executed" ?
            <>
              {dayjs(transaction.eta_time * 1000).format('D/M/YYYY')}
              <br />
              {dayjs(transaction.eta_time * 1000).format('h:m:s A')}
            </>
            :
            tab == 1 && transaction.status == 'L3Confirmed' ?
              <>
                {dayjs(transaction.eta_time * 1000).format('D/M/YYYY')}
                <br />
                {dayjs(transaction.eta_time * 1000).format('h:m:s A')}
              </>
              :
              transaction.status == "L1CanClaim" ?
                <div className={["SettingsModal_table_td_item_btn", claimLoading ? 'SettingsModal_table_td_item_btn_dis' : ''].join(' ')} onClick={() => claim(transaction)}>{t('Claim')}</div>
                :
                <CountTime
                  times={transaction.eta_time - dayjs().unix()}
                  onEndTimeHandle={() => getHistory(2)}
                  format="text"
                  endText={true}
                />

        }
      </div>
      <div className="SettingsModal_table_head_item SettingsModal_table_td_item SettingsModal_table_td_item5">
        {/* {
                          transaction.status == "L1CanClaim" ?
                            <div className={["SettingsModal_table_td_item_btn", claimLoading ? 'SettingsModal_table_td_item_btn_dis' : ''].join(' ')} onClick={() => claim(transaction)}>{t('Claim')}</div>
                            : ""
                        } */}

      </div>
    </div>
  );
};

export default TransactionTd;
